import React, { useEffect } from 'react'
// import LandingSection from '../components/homePage/section/LandingSection'
import VideoScrollSection from '../components/homePage/section/VideoScrollSection'
import ExpertiseSection from '../components/homePage/section/ExpertiseSection'
import GallerySection from '../components/homePage/section/GallerySection'
import ApprochSection from '../components/homePage/section/ApprochSection'
import FooterSection from '../components/homePage/section/FooterSection'
import { HoverProvider } from '../ContextProviders/HoverWrapperProvider'
import { m } from 'framer-motion'
import Navbar from '../components/Navbar'
import { LandingSection } from '../components/homePage/section/LandingSection2'
const Home = () => {
    useEffect(() => { document.title = "clekkflix" }, []);
    useEffect(() => {
        const scrollPosition = localStorage.getItem("scroll-position");
        if(!scrollPosition)return
        document.getElementById(scrollPosition)?.scrollIntoView()
        localStorage.removeItem("scroll-position");

    }, []);
 
    return (
        <m.main
            variants={{
                initial: { opacity: 0 },
                visible:{opacity:1},
                exit: { opacity: 0 }
            }}
            className='bg-white'
            initial="initial"
            exit={"exit"}
            animate={"visible"}
            transition={{
                duration:.35
            }}
        >

            <HoverProvider>
                <Navbar />
                <LandingSection />
                <VideoScrollSection />
                <ExpertiseSection />
                <ApprochSection />        
                <GallerySection />
                {/* <InsideSection /> */}
                <FooterSection />
            </HoverProvider>
        </m.main>
    )
}

export default Home
