import React from 'react'
import Button from './buttons/Button'
import { Circle } from 'lucide-react'

interface PropTypes{
    children:React.ReactNode,
    length:number,
    onClick: () => void;
    isActive:boolean
}
const GalleryFilter = ({children,length,onClick,isActive }:PropTypes) => {
  return (
    <Button onClick={onClick} closeInUnderline={false} className={`transition duration-300 ${isActive?"text-white":"text-gray-500"} hover:underline flex items-center gap-1`}>
      <Circle size={8} fill={isActive?"white":""} className='inline'/>
      <p className='text-sm'>{children} <sup>{length}</sup></p>
    </Button>
  )
}

export default GalleryFilter
