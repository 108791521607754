import React, { useEffect, useState } from 'react';
import ComeUpPageWrapper from '../components/ComeUpPageWrapper';
import Service from '../components/servicesPage/Service';
import Button from '../components/buttons/Button';
import { Link } from 'react-router-dom';

const Services = () => {
    useEffect(() => { document.title = "services" }, []);
    const [servicesData,setservicesData] = useState<any[]>([])
    useEffect(()=>{
        fetch("content/Services.json").then(r => r.json()).then(data=>{
            setservicesData(data)
        })
    },[])
    return (
    <ComeUpPageWrapper>
      <div className="flex flex-col text-center sm:text-left sm:flex-row justify-around max-w-[1500px] m-auto">
        <h1 className='text-4xl text-orange-600 font-medium'>Our Services</h1>
        <p className='sm:w-[65%] text-2xl font-normal'>
        We specialize in turning your digital vision into reality. Our team of experts works closely with you to develop tailored solutions that perfectly suit your unique needs. From initial brainstorming to final implementation, we're here to support you every step of the way.
        </p>
      </div>

      <hr className='my-12 border-gray-300' />

      {servicesData.map((service, index) => (
        <React.Fragment key={index}>
          <Service
            href={service.href}
            img={service.img}
            title={service.title}
            description={service.description}
          />
          <hr className='my-12 border-gray-300' />
        </React.Fragment>
      ))}

      <div className='w-[65%] float-right px-5 max-w-[1500px]'>
        <p className='mt-24 text-orange-300 text-2xl'>
          At ClekkFlix, we don’t just deliver services; we partner with you to drive real digital transformation. Working with talented agencies and companies, we’re focused on making an impact where it matters most, your success.
        </p>

        <div className="mt-10">
          <p>Have a digital project in mind?</p>
          <Link to="/contact-us"><Button className='mt-2 text-3xl'>Contact us</Button></Link>
        </div>
      </div>
    </ComeUpPageWrapper>
  );
};

export default Services;
