import React, { useEffect, useState } from 'react';
import ComeUpPageWrapper from '../components/ComeUpPageWrapper';
import Feature from '../components/FeatureCard';
import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';

const WhyChooseUs = () => {
    useEffect(() => { document.title = "why choose us" }, []);
    const [featuresData,setfeaturesData] = useState<any[]>([])
    useEffect(()=>{
        fetch("content/WhyChooseUs.json").then(r => r.json()).then(data=>{
            setfeaturesData(data)
        })
    },[])
    return (
    <ComeUpPageWrapper>
      <div className="flex flex-col text-center sm:text-left sm:flex-row justify-around mb-14 max-w-[1500px] m-auto">
        <h1 className='text-4xl text-orange-600 font-medium'>Our Expertise</h1>
        <p className='sm:w-[65%] text-2xl font-normal'>Our team of around 20 passionate experts designs personalised digital solutions. From advice to design to technical implementation, we make your entire digital journey.
        </p>
      </div>

      <hr className='my-12 border-gray-300' />

      {featuresData.map((feature, index) => (
        <div key={index}>
          <Feature
            img={feature.img}
            title={feature.title}
            description={feature.description}
          />
          <hr className='my-12 border-gray-300' />
        </div>
      ))}

      <div className='w-[65%] float-right px-5 max-w-[1500px]'>
        <p className='mt-24 text-orange-300 text-2xl'>
        We believe that digital transformation requires more than just expertise, it requires
collaboration and innovation. That’s why we partner with forward-thinking agencies and
companies to make sure your project is a success.
        </p>

        <div className="mt-10">
          <p>Have a digital project in mind?</p>
          <Link to={"/contact-us"}><Button className='mt-2 text-3xl'>Contact us</Button></Link>
        </div>
      </div>
    </ComeUpPageWrapper>
  );
};

export default WhyChooseUs;
