import React from 'react'
import Button from '../../buttons/Button';
import { Link } from 'react-router-dom';
import { Instagram, Linkedin, Locate, LocateFixed, PhoneCall } from 'lucide-react';

// const vid = require("../../../media/showreel2.mp4");
const logo = require("../../../media/logo.png")
const WA = require("../../../media/wa.png")
const FooterSection = () => {
  return (
    <>
      {/* <div style={{ zIndex: 1199 }} className="sticky w-full bg-white h-10"></div> */}
      <section onClick={() => localStorage.setItem("scroll-position", "footer")} id='footer' style={{ backgroundColor: "#262525", zIndex: 999 }} className='p-9 py-12 flex flex-col justify-center   text-white gap-2 items-center  text-center sm:text-left relative !bg-gray-950'>
        <div className='flex flex-col  lg:flex-row  text-white gap-12  w-full items-center lg:items-start justify-between'>
          <div className='flex gap-6 flex-col justify-between items-center sm:items-start max-w-[410px]'>
            <div className='flex flex-col text-center  items-center lg:text-left lg:items-start'>
              <div className='bg-white rounded-md p-2 py-4 sm:max-w-[200px]'>
                <img   src={logo} className='rounded-md m-auto w-full' alt="" />
              </div>
              <h2 className='text-2xl  mt-4'>
                Creating marvelous user experience
                for your brand, seamlessly.
              </h2>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full lg:w-[65%] mb-6 gap-6">
            <div className='md:w-[25%]'>
              <h3 className='mb-4 font-semibold text-lg [&_p]:text-xl text-center lg:text-left text-orange-600 ' >Quick Links</h3>
              <ul className='transition duration-300 text-xl space-y-2  text-center lg:text-left hover:text-gray-800 text-gray-400'>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/process'}>Our approach</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/services'}>Our services</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/why-choose-us'}>Our expertise</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/contact-us'}>Contact us</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/team'}>Meet our team</Link>
                </li>
              </ul>
            </div>
            <div className='md:w-[25%]'>
              <h3 className='mb-4 font-semibold text-lg [&_p]:text-xl text-center lg:text-left text-orange-600 ' >Services</h3>
              <ul className='transition duration-300 text-xl space-y-2  hover:text-gray-800 text-center text-gray-400 lg:text-left'>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/process'}>E-learning</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/services'}>Illustration</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/why-choose-us'}>Animations</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/contact-us'}>Translation</Link>
                </li>
                <li className='transition duration-300 hover:text-gray-400 cursor-pointer'>
                  <Link to={'/team'}>Audio & Video</Link>
                </li>
              </ul>
            </div>
            <div className='md:w-[25%] text-center text-gray-400 lg:text-left ' >
              <h3 className='mb-4 font-semibold text-lg [&_p]:text-xl text-center lg:text-left text-orange-600 ' >Contact</h3>
              <p className='text- text-base'><LocateFixed size={14} className='inline' />&nbsp;Eureka Towers C-802, Mindspace, Malad West</p>
              <p className='text-base mt-2 mb-2'><Locate size={14} className='inline' />&nbsp;Mumbai 400064</p>
              <Link to={"tel:919920010885"}>
                <Button className='text-base'><PhoneCall size={14} className='inline' />&nbsp;+91 99200 10885</Button>
              </Link> 
            </div>

            <div className='md:w-[25%] text-center lg:text-left ' >
              
              <h3 className='mb-4 font-semibold text-lg [&_p]:text-xl text-center lg:text-left text-orange-600 ' >Follow us</h3>
              <div className="flex gap-2 flex-wrap justify-center lg:justify-start mt-4 hover:text-gray-800">
                <Link target='_blank' to={"https://www.instagram.com/clekkflix"}>
                  <i className='transition duration-300 hover:text-pink-600 cursor-pointer'>
                    <Instagram size={24} />
                  </i>
                </Link>
                <Link target='_blank' to={"https://www.linkedin.com/in/clekk-flix-17304245/"}>
                  <i className='transition duration-300  hover:text-blue-600 cursor-pointer'>
                    <Linkedin strokeWidth={1.12} size={24} />
                  </i>
                </Link>
                <Link to={"https://wa.me/919920010885"}>
                  <img src={WA} width={24} alt="text on whatsapp" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className=""></div>
        <div className='flex flex-wrap justify-between flex-col sm:flex-row gap-6 w-full'>

        </div>

        {/* <video muted loop controls autoPlay playsInline src={vid} width={200} className='transition duration-300 hover:scale-110 object-contain absolute left-8 bottom-8 hidden sm:block'></video> */}
      </section>
    </>
  )
}

export default FooterSection
