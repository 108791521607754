import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import { m } from "framer-motion";
import { BackgroundBeams } from "../../background-effects/BackgroundBeams";
const vid = require("../../../media/showreel2.mp4");

export const LandingSection = () => {
  return (
    <>
    {/* <BackgroundLines> */}
      <section className="min-h-[99vh] w-full flex flex-col justify-center items-center text-center overflow-hidden relative bg-gray-900">
        <BackgroundBeams className="!bg-gray-900">
          <div
            className="relative z-50 flex flex-col justify-center items-center transform md:w-[50%] w-[90%] "
          >
            <video muted loop controls autoPlay playsInline src={vid} width={400} className='transition mb-8 duration-300 hover:scale-110 object-contain   left-8 bottom-8 '></video>
            <m.h1
              variants={{
                visible: { width: "100%" },
                hidden: { width: "0em" }
              }}
              initial={"hidden"}
              animate={"visible"}
              className='overflow-hidden text-center md:max-w-[600px] max-w-[400px] mb-5  whitespace-nowrap text-3xl sm:text-4xl md:text-4xl lg:text-5xl py-2 bg-orange-400 text-white font-extralight rounded-lg'
              transition={{
                type: "spring",
                stiffness: 75,
                duration: 2.5,
                delay: 1
              }}
            >
              &nbsp;Beyond The Frame&nbsp;
            </m.h1>
            <h1
              className="text-2xl sm:text-3xl md:text-4xl font-normal tracking-wider !leading-tight text-orange-600 rounded-lg"
            >
              Transforming Visions into Digital Excellence crafted to elevate your game.
            </h1>
            {/* <Link to={"/contact-us"}>
              <button className='whitespace-nowrap text-orange-400 mt-5 transition-all duration-500 w-[9.65rem] -translate-x-5 hover:-translate-x-2 p-2'>
                &nbsp;Let's Work Together&nbsp;
                <i className='inline'>
                  <ArrowRight className='inline' size={25} />
                </i>
              </button>
            </Link> */}
            <m.button
              className='block overflow-hidden px-2 py-1 border rounded-full border-black m-auto mt-5 w-48 text-orange-400 border-orange-600'
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 25 }
            }}
            initial={"hidden"}
            animate={"visible"}
            transition={{
                type: "spring",
                stiffness: 75,
                duration: 2.5,
                delay: 3
            }}
            >
              <Link to={"/contact-us"}>
                <button className='whitespace-nowrap transition-all duration-500 w-[9.65rem] -translate-x-[38.5px] hover:-translate-x-2'>
                  <i className='inline'>
                    <ArrowRight className='inline' size={25} />
                  </i>
                  &nbsp;Let's Work Together&nbsp;
                  <i className='inline'>
                    <ArrowRight className='inline' size={25} />
                  </i>
                </button>
              </Link>
            </m.button >
          </div>
          {/* <BoxesContainer /> */}
        </BackgroundBeams>
      </section>
    {/* </BackgroundLines> */}
    </>
  );
}; 