import React, { useEffect, useRef, useState } from 'react';
import { m, useScroll, useTransform } from 'framer-motion';
import { Star } from 'lucide-react';
import GalleryFilter from '../../GalleryFilter';
import GalleryPotrait, { Potrait } from '../../GalleryPotrait';

const GallerySection = () => {
    const [galleryData,setgalleryData] = useState<any[]>([])
    useEffect(()=>{
        fetch("content/Gallery.json").then(r => r.json()).then(data=>{
            setgalleryData(data)
        })
    },[])
    const element = useRef<HTMLDivElement>(null);
    const [hovered, setHover] = useState<number[] | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]); // Track selected categories
    const { scrollYProgress } = useScroll({ target: element });

    const y = useTransform(scrollYProgress, [0, 1], [0, -100]);

    // Handle filter selection and deselection
    const handleFilterClick = (category: string) => {
        setSelectedCategories((prevSelectedCategories) => {
            if (prevSelectedCategories.includes(category)) {
                // If the category is already selected, remove it
                return prevSelectedCategories.filter((cat) => cat !== category);
            } else {
                // Otherwise, add it
                return [...prevSelectedCategories, category];
            }
        });
    };

    // Filter the gallery based on selected categories
    const filteredGalleryData = selectedCategories.length
        ? galleryData.filter((group) => selectedCategories.includes(group.category)) // Show images matching any selected category
        : galleryData; // Show all if no categories are selected

    return (
        <section
            onClick={() => localStorage.setItem('scroll-position', 'portfolio')}
            id='portfolio'
            style={{ backgroundColor: '#181717' }}
            className='p-9 py-12 text-white gap-16'
        >
            <h1 className='text-[5vw] whitespace-nowrap text-center mb-7 text-orange-600'>
                <Star size={35} className='inline fill-orange-600 text-orange-500' />
                &nbsp; Our portfolio
            </h1>

            {/* Filters */}
            <div className='flex gap-6 flex-wrap justify- mb-7'>
                {galleryData.map((group, i) => (
                    <GalleryFilter
                        key={i}
                        length={group.images.length}
                        onClick={() => handleFilterClick(group.category)}  
                        isActive={selectedCategories.includes(group.category)} 
                    >
                        {group.category}
                    </GalleryFilter>
                ))}
            </div>

            {/* Gallery */}
            <div ref={element} className='gallery hidden sm:flex flex-col sm:flex-row sm:gap-5 min-h-[962px]'>
                {filteredGalleryData.map((group, i) => (
                    <m.div
                        key={i}
                        style={{ ...(i !== 1 && { y }) }}
                        className='flex flex-col gap-8 transition-all duration-500 lg:transition-none'
                    >
                        {group.images.map((potrait:any, j:any) => (
                            <GalleryPotrait
                                key={`${i}-${j}`}
                                overlay={hovered && hovered.toString() !== [i, j].toString()}
                                onHover={() => setHover([i, j])}
                                onLeave={() => setHover(null)}
                                potrait={potrait as Potrait}
                            />
                        ))}
                    </m.div>
                ))}
            </div>

            {/* Mobile Gallery */}
            <div className='gallery flex sm:hidden flex-col sm:flex-row sm:gap-5 min-h-[962px]'>
                {filteredGalleryData.map((group, i) => (
                    <div key={i} className='flex flex-col gap-8 transition-all duration-500 lg:transition-none'>
                        {group.images.map((potrait:any, j:any) => (
                            <GalleryPotrait
                                key={`${i}-${j}`}
                                overlay={hovered && hovered.toString() !== [i, j].toString()}
                                onHover={() => setHover([i, j])}
                                onLeave={() => setHover(null)}
                                potrait={potrait as Potrait}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default GallerySection;
