import React, { useEffect, useState } from 'react';
import SectionHeading from '../../headings/SectionHeading';
import Button from '../../buttons/Button';
import Expertise from '../../Expertise';
import { Link } from 'react-router-dom';


const ExpertiseSection = () => {
    const [servicesData,setservicesData] = useState<any[]>([])
    useEffect(()=>{
        fetch("content/Expertise.json").then(r => r.json()).then(data=>{
            setservicesData(data)
        })
    },[])
    return (
        <section
            onClick={() => localStorage.setItem("scroll-position", "expertise")}
            id="expertise"
            className="py-20 bg-slate-10 bg-gray-900 text-white px-10 flex flex-col items-center text-center md:text-left md:flex-row md:items-start md:gap-28 gap-20 justify-around"
        >
            <div className="md:sticky top-20">
                <div className="sticky top-10 w-full md:max-w-[180px] flex flex-col gap-3">
                    <SectionHeading className="justify-center md:justify-start [&>p]:text-2xl md:[&>p]:text-sm">
                        Our Services
                    </SectionHeading>
                    <p className="text-xl md:text-sm">Services dedicated to enhancing your success</p>
                    <Link to="/services" className="mt-4 font-light text-2xl md:text-sm">
                        <Button>View all services</Button>
                    </Link>
                </div>
            </div>
            <div className="text-4xl md:text-[3.35vw] gap-9 md:gap-6 sm:text-6xl tracking-wider group flex items-center md:items-start flex-col">
                {servicesData.map((service, index) => (
                    <Link key={index} to={service.path}>
                        <Expertise>{service.name}</Expertise>
                    </Link>
                ))}
            </div>
        </section>
    );
};

export default ExpertiseSection;
