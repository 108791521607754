import React, { useEffect, useState } from 'react';
import ComeUpPageWrapper from '../components/ComeUpPageWrapper';
import ProcessCard from '../components/processPage/Process';
import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';

const Process = () => {
    const [processData,setprocessData] = useState<any[]>([])
    useEffect(()=>{
        fetch("content/Process.json").then(r => r.json()).then(data=>{
            setprocessData(data)
        })
    },[])

    useEffect(() => { document.title = "our process" }, []);

    return (
        <ComeUpPageWrapper>
            <div className="flex flex-col sm:flex-row justify-around mb-14 max-w-[1500px] m-auto">
                <h1 className='text-4xl text-orange-600 font-medium'>Our Approach</h1>
                <p className='sm:w-[65%] text-2xl font-normal'>
                    Our team of dedicated experts specialises in creating tailored digital solutions that perfectly
                    fit your unique vision. From the initial idea to the final execution, we’re with you every step of
                    the way, making sure your digital journey is smooth and successful. Here's a look at how we
                    bring your ideas to life!
                </p>
            </div>

            {processData.map((step, index) => (
                <ProcessCard
                    key={index}
                    img={step.img}
                    index={step.index}
                    title={step.title}
                    description={step.description}
                />
            ))}

            <div className='w-[65%] float-right px-5 max-w-[1500px]'>
                <p className='mt-24 text-orange-300 text-2xl'>
                    We don’t just solve challenges; we drive digital transformation. By partnering with top
                    agencies and companies, we stay focused on making a meaningful impact.
                </p>

                <div className="mt-10">
                    <p>Have a digital project in mind?</p>
                    <Link to={"/contact-us"}><Button className='mt-2 text-3xl'>Contact us</Button></Link>
                </div>
            </div>
        </ComeUpPageWrapper>
    );
};

export default Process;
